<template>
  <AppView>
    <!-- content @s -->
    <div class="nk-content nk-content-fluid">
      <div class="container-xl wide-xl">
        <div class="nk-content-inner">
          <div class="nk-content-body">
            <div class="nk-block-head nk-block-head-sm">
              <div class="nk-block-between g-3">
                <div class="nk-block-head-content">
                  <h3 class="nk-block-title page-title">Servicios / <strong class="text-primary small">Nuevo Servicio</strong></h3>
                </div>
                <div class="nk-block-head-content">
                  <router-link to="/sistema/servicios" replace class="btn btn-outline-light bg-white d-none d-sm-inline-flex"><em class="icon ni ni-arrow-left"></em><span>Regresar</span></router-link>
                  <router-link to="/sistema/servicios " replace class="btn btn-icon btn-outline-light bg-white d-inline-flex d-sm-none"><em class="icon ni ni-arrow-left"></em></router-link>
                </div>
              </div>
            </div><!-- .nk-block-head -->
            <div class="nk-block">
              <div class="card card-bordered">
                <div class="card-aside-wrap">
                  <div class="card-content">
                    <form @submit.prevent="save">
                      <div class="card-inner">
                        <div class="nk-block">
                          <div class="nk-block-head">
                            <h5 class="title">Nuevo Servicio</h5>
                          </div><!-- .nk-block-head -->
                          <div class="form-group">
                            <label class="form-label">Nombre <span class="ml-1 text-danger">*</span></label>
                            <input type="text" class="form-control" autocomplete="nope" v-model="model.nombre" required>
                          </div>
                          <div class="form-group">
                            <label class="form-label">Descripción</label>
                            <textarea class="form-control" v-model="model.descripcion"></textarea>
                          </div>
                          <div class="form-group">
                            <label class="form-label">Registro Patronal ante el IMSS <span class="ml-1 text-danger">*</span></label>
                            <input type="text" class="form-control" autocomplete="nope" v-model="model.registro_patronal" required>
                          </div>
                          <div class="form-group">
                            <label class="form-label">Calle (centro del trabajo) <span class="ml-1 text-danger">*</span></label>
                            <input type="text" class="form-control" autocomplete="nope" v-model="model.calle" required>
                          </div>
                          <div class="form-group">
                            <label class="form-label">Número exterior (centro del trabajo) <span class="ml-1 text-danger">*</span></label>
                            <input type="text" class="form-control" autocomplete="nope" v-model="model.no_exterior" required>
                          </div>
                          <div class="form-group">
                            <label class="form-label">Número interior (centro de trabajo) <span class="ml-1 text-danger">*</span></label>
                            <input type="text" class="form-control" autocomplete="nope" v-model="model.no_interior" required>
                          </div>
                          <div class="form-group">
                            <label class="form-label">Colonia (centro de trabajo) <span class="ml-1 text-danger">*</span></label>
                            <input type="text" class="form-control" autocomplete="nope" v-model="model.colonia" required>
                          </div>
                          <div class="form-group">
                            <label class="form-label">Código Postal (centro de trabajo) <span class="ml-1 text-danger">*</span></label>
                            <input type="text" class="form-control" autocomplete="nope" v-model="model.codigo_postal" required>
                          </div>
                          <div class="form-group">
                            <label class="form-label">Entidad federativa (centro de trabajo)</label>
                            <select-estado v-model:estado="model.id_estado" class="mb-2"></select-estado>
                          </div>
                          <div class="form-group">
                            <label class="form-label">Municipio o Alcaldía (centro de trabajo)</label>
                            <select-municipio :estado="model.id_estado" v-model:municipio="model.id_municipio" class="mb-2"></select-municipio>
                          </div>
                          <div class="form-group">
                            <label class="form-label">Ubicación <span class="ml-1 text-danger">*</span></label>
                            <input type="text" class="form-control" autocomplete="nope" v-model="model.ubicacion" required>
                          </div>
                          <div class="form-group">
                            <label class="form-label">No. Contrato <span class="ml-1 text-danger">*</span></label>
                            <input type="text" class="form-control" autocomplete="nope" v-model="model.no_contrato" required>
                          </div>
                          <div class="form-group">
                            <label class="form-label">Sitio <span class="ml-1 text-danger">*</span></label>
                            <input type="text" class="form-control" autocomplete="nope" v-model="model.sitio" required>
                          </div>
                          <div class="w-100 text-right">
                            <button type="submit" class="mt-2 btn btn-lg btn-primary">Guardar Servicio</button>
                          </div>
                        </div><!-- .nk-block -->
                      </div><!-- .card-inner -->
                    </form>
                  </div><!-- .card-content -->
                </div><!-- .card-aside-wrap -->
              </div><!-- .card -->
            </div><!-- .nk-block -->
          </div>
        </div>
      </div>
    </div>
    <!-- content @e -->
  </AppView>
</template>

<script>
import AppView from "@/components/sistema/AppView";
import {useApi} from "@/use/useApi";
import { ref, defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import Swal from "sweetalert2";
import SelectEstado from "@/components/globales/SelectEstado";
import SelectMunicipio from "@/components/globales/SelectMunicipio";

export default defineComponent({
  name: "Nuevo Servicio",
  components: {AppView, SelectEstado, SelectMunicipio},
  setup() {
    const { loading, make } = useApi();

    const router = useRouter();

    const model = ref({
      nombre: '',
      descripcion: '',
      registro_patronal: '',
      calle: '',
      no_exterior: '',
      no_interior: '',
      colonia: '',
      codigo_postal: '',
      id_estado: '',
      id_municipio: '',
      ubicacion: '',
      no_contrato: '',
      sitio: ''
    });

    async function save(){
      loading.message = 'Guardando usuario';
      let {data} = await make('servicios/new', {model: model.value});
      if(data === null){
        Swal.fire('Error', 'Ocurrió un error al conectarse con el servidor', 'error');
      } else {
        if(data.error){
          Swal.fire('Error', data.message, 'error');
        } else {
          Swal.fire('Éxito', data.message, 'success').then(() => {
            router.push('/sistema/servicios');
          });
        }
      }
    }

    return {model, save};
  }
});
</script>

<style scoped>
.profile-ud-list {
  max-width: inherit;
}
.profile-ud-label {
  width: 140px;
  align-items: start;
  margin-top: 0.5em;
}
.profile-ud-value {
  line-height: 2.5em;
}
</style>